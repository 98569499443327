import { StatelessComponent } from 'react';
import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';

interface Props {
  to: string;
  target?: string;
  className?: string;
}

/**
 * Like Gatsby Link but uses plain `<a>` for external links. Internal links are those that start
 * with a slash (e.g., `/pricing/`), an anchor (e.g., `#faq`) or literal 'https://versionpress.com'.
 */
const Link: StatelessComponent<Props> = props => {
  // tslint:disable-next-line:prefer-const
  let { to, ...propsWithoutTo } = props;

  // https://regex101.com/r/quV30F/3
  to = to.replace(/^https:\/\/versionpress\.com(\/)?/, '/');

  // https://regex101.com/r/g5TBob/3/tests
  const isInternalLink = /^(\/[^\/]|#|\/$)/.test(to);

  return isInternalLink ? <GatsbyLink to={to} {...propsWithoutTo} /> : <a href={to} {...propsWithoutTo} />;
};

export default Link;
