import * as React from 'react';
import Link from '../components/Link';
import Search from '../components/search/Search';

const Header: React.StatelessComponent = () => (
  <>
    <div className='header'>
      <nav className='navbar navbar-expand-md navbar--bold navbar-inverse bg-base-1'>
        <div className='container navbar-container'>
          <Link className='navbar-brand' to='/'>
            VersionPress
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbar_main'
            aria-controls='navbarsExampleDefault'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <i className='fas fa-bars' />
          </button>
          <div className='collapse navbar-collapse align-items-center justify-content-end' id='navbar_main'>
            <ul className='navbar-nav pr-4'>
              <li className='nav-item'>
                <Link to='https://github.com/versionpress/versionpress' className='nav-link'>
                  GitHub
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='https://docs.versionpress.net/' className='nav-link'>
                  Docs
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/blog/' className='nav-link'>
                  Blog
                </Link>
              </li>
              <li className='nav-item nav-search'>
                <Search />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </>
);

export default Header;
