import * as simpleLoadScript from 'simple-load-script';

export async function loadScript(url: string, options?: { inBody: boolean }) {
  return simpleLoadScript(url, options);
}

export function removeScript(src: string) {
  const scripts = document.getElementsByTagName('script');
  for (const script in scripts) {
    if (scripts[script].src === src) {
      scripts[script].remove();
    }
  }
}
