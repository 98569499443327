import * as React from 'react';
import { ReactSVG } from 'react-svg';
import Link from '../components/Link';

const Footer: React.StatelessComponent = () => (
  <footer id='footer' className='footer'>
    <div className='footer-top'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <div className='copyright mt-4'>
              <p className='mb-0'>
                Copyright © {new Date().getFullYear()} <strong>VersionPress</strong>
              </p>
            </div>
            <ul className='social-media social-media--style-1-v4'>
              <li>
                <a href='https://www.facebook.com/VersionPress/' className='facebook' target='_blank' title='Facebook'>
                  <ReactSVG src='https://unpkg.com/@icon/feather/icons/facebook.svg' />
                </a>
              </li>
              <li>
                <a href='https://twitter.com/versionpress' className='twitter' target='_blank' title='Twitter'>
                  <ReactSVG src='https://unpkg.com/@icon/feather/icons/twitter.svg' />
                </a>
              </li>
              <li>
                <a href='https://github.com/versionpress' className='github' target='_blank' title='GitHub'>
                  <ReactSVG src='https://unpkg.com/@icon/feather/icons/github.svg' />
                </a>
              </li>
            </ul>
            <p className='mt-5'>
              See also our <Link to='/hosting/'>WordPress hosting</Link>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
