import * as React from 'react';
import Helmet from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import { loadScript } from '../utils/scripts-manager';
import { withPrefix } from 'gatsby-link';

import '../styles/main.scss';
import Favicons from '../components/Favicon';

interface Props {
  className?: string;
}

class Layout extends React.Component<Props> {
  async componentDidMount() {
    if (!global.bootstrapScriptsLoaded) {
      // Minimal set of libs to make `boomerang.min.js` run without errors. For full list they use in the demo page, see
      // https://github.com/versionpress/boomerang/blob/245e573c745322569af7db0054a2a389c2b1c23f/demos/startup-app/index.html#L622-L667
      await loadScript('https://code.jquery.com/jquery-3.3.1.slim.min.js');
      await loadScript('https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js', { inBody: true });
      await loadScript('https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js', { inBody: true });
      await loadScript(
        'https://cdnjs.cloudflare.com/ajax/libs/jquery.adaptive-backgrounds/1.0.3/jquery.adaptive-backgrounds.min.js',
        { inBody: true }
      );
      await loadScript('https://cdnjs.cloudflare.com/ajax/libs/sticky-kit/1.1.3/sticky-kit.min.js', { inBody: true });
      await loadScript(
        'https://cdnjs.cloudflare.com/ajax/libs/jQuery-viewport-checker/1.8.8/jquery.viewportchecker.min.js',
        { inBody: true }
      );
      await loadScript('https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js', { inBody: true });
      await loadScript('https://cdnjs.cloudflare.com/ajax/libs/jquery.imagesloaded/4.1.4/imagesloaded.pkgd.min.js', {
        inBody: true,
      });

      // Now, finally:
      await loadScript(withPrefix('assets/js/boomerang.min.js'), { inBody: true });

      global.bootstrapScriptsLoaded = true;
    }

    $(() => {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  render() {
    return (
      <>
        <Favicons />
        <Helmet titleTemplate='%s · VersionPress'>
          <link rel='preconnect' href='https://code.jquery.com' />
          <link rel='preconnect' href='https://stackpath.bootstrapcdn.com' />
          <link rel='preconnect' href='https://unpkg.com' />
          <meta name='Description' content='WordPress + Git = VersionPress' />

          <link
            rel='stylesheet'
            type='text/css'
            href='https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css'
          />
          <link
            rel='stylesheet'
            type='text/css'
            href='https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.4.1/jquery.fancybox.min.css'
          />
        </Helmet>
        <div className={`body-wrap ${this.props.className}`}>
          <Header />
          {this.props.children}
          <Footer />
        </div>
      </>
    );
  }
}

export default Layout;
