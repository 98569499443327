import * as React from 'react';
import Helmet from 'react-helmet';
import { navigate } from 'gatsby';
import { loadScript } from '../../utils/scripts-manager';

interface State {
  isInputFocused: boolean;
}

class Search extends React.Component<{}, State> {
  private inputNode: HTMLInputElement | null = null;

  state = {
    isInputFocused: false,
  };

  async componentDidMount() {
    if (!global.algoliaScriptLoaded) {
      await loadScript('https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.js');
      global.algoliaScriptLoaded = true;
    }

    (window as any).docsearch({
      appId: 'VO0GDG0RAZ',
      // Only search API key, it's not a secret
      apiKey: '9d130446a065fee756c82c04e2ec6bc0',
      indexName: 'versionpress.com',
      inputSelector: '#site-search-input',
      handleSelected: (input: any, event: any, suggestion: { url: string }) => {
        const url = new URL(suggestion.url);
        navigate(url.pathname + url.hash);
      },
    });

    document.addEventListener('keyup', this.onKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyUp);
  }

  onKeyUp = (e: KeyboardEvent) => {
    if (e.code === 'KeyS' && this.inputNode) {
      this.inputNode.focus();
    }
  };

  onIconClick = () => {
    if (this.inputNode) {
      this.inputNode.focus();
    }
  };

  onFocus = () => {
    this.setState({ isInputFocused: true });
  };

  onBlur = () => {
    this.setState({ isInputFocused: false });
  };

  render() {
    const { isInputFocused } = this.state;

    return (
      <div className='site-search'>
        <Helmet>
          <link
            rel='stylesheet'
            type='text/css'
            href='https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.css'
          />
        </Helmet>
        <form className='search-group'>
          {/*
            A simple CSS selector would not work since Algolia wraps the input into its own element.
            That's why there is a separate state for focus management.
          */}
          <i
            className={`icon fas fa-search search-icon${isInputFocused ? ' focused' : ''}`}
            onClick={this.onIconClick}
          />
          <input
            ref={node => (this.inputNode = node)}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            id='site-search-input'
            className='form-control'
            type='text'
            placeholder='Search'
          />
        </form>
      </div>
    );
  }
}

export default Search;
