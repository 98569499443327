import * as React from 'react';
import Helmet from 'react-helmet';

const Favicons: React.SFC = () => (
  <Helmet>
    <link
      rel='apple-touch-icon'
      sizes='180x180'
      href='https://res.cloudinary.com/vpimg/image/upload/c_scale,w_180,f_auto/v1542366484/versionpress.com/logo/logo-lgs.png'
    />
    {/* Single size used intentionally as Chrome fetches them all, see https://stackoverflow.com/q/40187301/21728 */}
    <link
      rel='icon'
      type='image/png'
      sizes='32x32'
      href='https://res.cloudinary.com/vpimg/image/upload/c_scale,w_32,f_auto/v1542366484/versionpress.com/logo/logo-lgs.png'
    />
  </Helmet>
);

export default Favicons;
